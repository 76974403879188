import './bootstrap';

import Alpine from 'alpinejs';

import { DatatablesProvider as Datatables} from './datatables/datatables';

window.Alpine = Alpine;

Alpine.start();

// Crear un objeto URL con la ubicación actual de la ventana
let url = new URL(window.location.href);
let pathname = url.pathname.replace(/\/+$/, '');
pathname = pathname.split('/').pop();

var module = import(`./modules/${pathname}.js`);

// Cuando el módulo se haya cargado
module.then(module => {
	// Obtener el export por defecto del módulo como ServiceHandler
	const ServiceHandler = module.default;
	// Crear una nueva instancia de ServiceHandler con el token CSRF
	const service = new ServiceHandler();

	// Crear una nueva instancia de Datatables y llamar al método dispatch
	if (service.hasOwnProperty('TABLE') && service.hasOwnProperty('DATATABLES_COLUMNS'))
		new Datatables(service.TABLE, service.DATATABLES_COLUMNS).dispatch()

	// Llamar al método dispatch en la instancia de service
	service.dispatch()
})
// Manejar errores al cargar el módulo
.catch(err => {
	console.error('Error al cargar el módulo:', err);
});
